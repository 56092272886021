var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comprehensive-table-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-top-panel",
            [
              _c(
                "el-form",
                {
                  ref: "queryForm",
                  attrs: {
                    inline: true,
                    "label-width": "100px",
                    model: _vm.queryForm
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c("div", { staticClass: "cont-form" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "jobNo" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              attrs: {
                                placeholder: "运单号",
                                rows: 3,
                                type: "textarea"
                              },
                              model: {
                                value: _vm.queryForm.jobNo,
                                callback: function($$v) {
                                  _vm.$set(_vm.queryForm, "jobNo", $$v)
                                },
                                expression: "queryForm.jobNo"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "柜号", prop: "cabinetNo" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "180px" },
                              attrs: { clearable: "", placeholder: "请输入" },
                              model: {
                                value: _vm.queryForm.cabinetNo,
                                callback: function($$v) {
                                  _vm.$set(_vm.queryForm, "cabinetNo", $$v)
                                },
                                expression: "queryForm.cabinetNo"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "my-form-item",
                            attrs: { label: "是否混箱", prop: "isMixed" }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择类型"
                                },
                                model: {
                                  value: _vm.queryForm.isMixed,
                                  callback: function($$v) {
                                    _vm.$set(_vm.queryForm, "isMixed", $$v)
                                  },
                                  expression: "queryForm.isMixed"
                                }
                              },
                              _vm._l(_vm.yseOrNo, function(item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "my-form-item",
                            attrs: { label: "报关方式", prop: "customsType" }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择类型"
                                },
                                model: {
                                  value: _vm.queryForm.customsType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.queryForm, "customsType", $$v)
                                  },
                                  expression: "queryForm.customsType"
                                }
                              },
                              _vm._l(_vm.customsType, function(item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "my-form-item",
                            attrs: { label: "清关方式", prop: "taxType" }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择类型"
                                },
                                model: {
                                  value: _vm.queryForm.taxType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.queryForm, "taxType", $$v)
                                  },
                                  expression: "queryForm.taxType"
                                }
                              },
                              _vm._l(_vm.taxType, function(item) {
                                return _c("el-option", {
                                  key: item,
                                  attrs: { label: item, value: item }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-search",
                            "native-type": "submit",
                            type: "primary"
                          },
                          on: { click: _vm.handleQuery }
                        },
                        [_vm._v(" 查询 ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { "native-type": "submit" },
                          on: {
                            click: function($event) {
                              return _vm.reset("queryForm")
                            }
                          }
                        },
                        [_vm._v(" 重置 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "vab-query-form-left-panel",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.loadingExPort,
                    size: "mini",
                    type: "primary"
                  },
                  on: { click: _vm.exPort }
                },
                [_vm._v(" 底单导出 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.loadingExPortPri,
                    size: "mini",
                    type: "primary"
                  },
                  on: { click: _vm.exPortPri }
                },
                [_vm._v(" 报关资料导出 ")]
              ),
              _c(
                "el-dropdown",
                {
                  attrs: { trigger: "click" },
                  on: { command: _vm.handleExportEuCommand }
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "mini", type: "primary" } },
                    [
                      _vm._v(" 欧线清关资料导出 "),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right"
                      })
                    ]
                  ),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", { attrs: { command: "YUHUI" } }, [
                        _vm._v("裕辉清关资料")
                      ]),
                      _c("el-dropdown-item", { attrs: { command: "CA" } }, [
                        _vm._v("CA清关资料")
                      ]),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "ZHUOYUE" } },
                        [_vm._v("卓越清关资料")]
                      ),
                      _c("el-dropdown-item", { attrs: { command: "ATHINA" } }, [
                        _vm._v("Athina清关资料")
                      ]),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "JACKSTAR" } },
                        [_vm._v(" Jackstar清关资料 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-dropdown",
                {
                  attrs: { trigger: "click" },
                  on: { command: _vm.handleExportEnCommand }
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "mini", type: "primary" } },
                    [
                      _vm._v(" 英线清关资料导出 "),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right"
                      })
                    ]
                  ),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", { attrs: { command: "KQ" } }, [
                        _vm._v("KQ清关资料")
                      ]),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "FOURPLINK" } },
                        [_vm._v("4Plink")]
                      ),
                      _c("el-dropdown-item", { attrs: { command: "DFS" } }, [
                        _vm._v("DFS")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.openColumnSettings }
                },
                [_vm._v(" 设置列 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 420px)" } },
        [
          _c(
            "vxe-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              ref: "tableRef",
              attrs: {
                id: "draftCopyTable",
                border: "",
                "checkbox-config": { trigger: "row", range: true },
                "custom-config": _vm.customConfig,
                data: _vm.tableData,
                height: "100%",
                "row-config": { isHover: true, isCurrent: true, height: 35 },
                "scroll-y": { enabled: true },
                "show-header-overflow": "",
                "show-overflow": ""
              },
              on: {
                "checkbox-all": _vm.selectAllEvent,
                "checkbox-change": _vm.selectAllEvent
              },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function() {
                    return [
                      _c("el-image", {
                        staticClass: "vab-data-empty",
                        attrs: {
                          src: require("@/assets/empty_images/data_empty.png")
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "selectOption",
                  fixed: "left",
                  type: "checkbox",
                  width: "55"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "indexOption",
                  fixed: "left",
                  title: "序号",
                  type: "seq",
                  width: "70"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var seq = ref.seq
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              seq +
                                (_vm.queryForm.pageNum - 1) *
                                  _vm.queryForm.pageSize
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "cabinetNo",
                  "min-width": "140",
                  title: "柜号"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "jobNo",
                  "min-width": "140",
                  title: "运单号"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "cusProductCnName",
                  "min-width": "140",
                  title: "客户中文品名"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "cusProductEnName",
                  "min-width": "140",
                  title: "客户英文品名"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "hsCode",
                  "min-width": "120",
                  title: "海关编码"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "reportCnName",
                  "min-width": "140",
                  title: "申报中文品名"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "reportEnName",
                  "min-width": "140",
                  title: "申报英文品名"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "hsCodeCn",
                  "min-width": "120",
                  title: "中国海关编码"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "reportFactor",
                  "min-width": "120",
                  title: "中国申报要素"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "materialCn",
                  "min-width": "140",
                  title: "中文材质"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "materialEn",
                  "min-width": "140",
                  title: "英文材质"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "childJobNo",
                  "min-width": "240",
                  "show-overflow-tooltip": "",
                  title: "子单号"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "brand",
                  "min-width": "140",
                  title: "品牌"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "brandType",
                  "min-width": "140",
                  title: "品牌类型"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "specs",
                  "min-width": "140",
                  title: "规格"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "uses",
                  "min-width": "140",
                  title: "用途"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "goodsType",
                  "min-width": "120",
                  title: "货物类型"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "unit",
                  "min-width": "80",
                  title: "单位"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "pcs",
                  "min-width": "80",
                  "show-overflow-tooltip": "",
                  title: "件数"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "netWeight",
                  "min-width": "100",
                  title: "净重"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "receiveNetWeight",
                  "min-width": "100",
                  title: "收货总净重"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "qty",
                  "min-width": "80",
                  title: "数量"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "vol",
                  "min-width": "80",
                  "show-overflow-tooltip": "",
                  title: "体积"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "splitVol",
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                  title: "拆分体积"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "grossWeight",
                  "min-width": "80",
                  title: "毛重"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "splitGrossWeight",
                  "min-width": "100",
                  title: "拆分毛重"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "receiveGrossWeight",
                  "min-width": "100",
                  title: "收货总实重"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "spliTreceiveGrossWeight",
                  "min-width": "120",
                  title: "拆分收货总实重"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "price",
                  "min-width": "80",
                  title: "单价"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "totalPrice",
                  "min-width": "80",
                  title: "总价"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "length",
                  "min-width": "80",
                  "show-overflow-tooltip": "",
                  title: "长"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "width",
                  "min-width": "80",
                  "show-overflow-tooltip": "",
                  title: "宽"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "height",
                  "min-width": "80",
                  "show-overflow-tooltip": "",
                  title: "高"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "transactionUrl",
                  fixed: "right",
                  "min-width": "80",
                  "show-overflow-tooltip": "",
                  title: "链接"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: row.transactionUrl,
                              target: "_blank"
                            }
                          },
                          [_vm._v(" " + _vm._s(row.transactionUrl) + " ")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "customsType",
                  "min-width": "80",
                  title: "报关方式"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "taxType",
                  fixed: "right",
                  "min-width": "80",
                  title: "清关方式"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "createBy",
                  "min-width": "100",
                  title: "创建人"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "createTime",
                  "min-width": "160",
                  title: "创建时间"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "isMixed",
                  fixed: "right",
                  "min-width": "80",
                  title: "是否混装"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: { type: row.isMixed ? "success" : "danger" }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(row.isMixed ? "是" : "否") + " "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "cont" }, [
        _vm._v(" 全部合计： "),
        _c("span", [
          _vm._v(
            "订单毛重：" +
              _vm._s(_vm._f("toFixedNum")(_vm.fomr.totalGrossWeight))
          )
        ]),
        _c("span", [
          _vm._v(
            "订单净重：" + _vm._s(_vm._f("toFixedNum")(_vm.fomr.totalNetWeight))
          )
        ]),
        _c("span", [
          _vm._v(
            "收货毛重：" +
              _vm._s(_vm._f("toFixedNum")(_vm.fomr.totalReceiveGrossWeight))
          )
        ]),
        _c("span", [
          _vm._v(
            "收货净重：" +
              _vm._s(_vm._f("toFixedNum")(_vm.fomr.totalReceiveNetWeight))
          )
        ]),
        _c("span", [_vm._v("件数：" + _vm._s(_vm.fomr.totalPcs))]),
        _c("span", [_vm._v("总数量：" + _vm._s(_vm.fomr.totalQty))]),
        _c("span", [
          _vm._v("金额：" + _vm._s(_vm._f("toFixedNum")(_vm.fomr.totalPrice)))
        ]),
        _c("span", [
          _vm._v("体积：" + _vm._s(_vm._f("toFixedNum")(_vm.fomr.totalVol)))
        ])
      ]),
      _c("div", { staticClass: "cont" }, [
        _vm._v(" 选中数据合计： "),
        _c("span", [_vm._v("订单毛重：" + _vm._s(_vm.selectForm.grossWeight))]),
        _c("span", [_vm._v("订单净重：" + _vm._s(_vm.selectForm.netWeight))]),
        _c("span", [
          _vm._v("收货毛重：" + _vm._s(_vm.selectForm.receiveGrossWeight))
        ]),
        _c("span", [
          _vm._v("收货净重：" + _vm._s(_vm.selectForm.receiveNetWeight))
        ]),
        _c("span", [_vm._v("件数：" + _vm._s(_vm.selectForm.pcs))]),
        _c("span", [_vm._v("总数量：" + _vm._s(_vm.selectForm.qty))]),
        _c("span", [_vm._v("金额：" + _vm._s(_vm.selectForm.totalPrice))]),
        _c("span", [_vm._v("体积：" + _vm._s(_vm.selectForm.vol))])
      ]),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNum,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          "page-sizes": [100, 200, 300, 500, 1000],
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c("Preview", { ref: "preview" }),
      _c("Sync", { ref: "sync" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }