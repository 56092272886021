<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="queryForm"
          :inline="true"
          label-width="100px"
          :model="queryForm"
          @submit.native.prevent
        >
          <div class="cont-form">
            <div>
              <el-form-item prop="jobNo">
                <el-input
                  v-model="queryForm.jobNo"
                  placeholder="运单号"
                  :rows="3"
                  style="width: 200px"
                  type="textarea"
                />
              </el-form-item>
            </div>
            <div>
              <el-form-item label="柜号" prop="cabinetNo">
                <el-input
                  v-model="queryForm.cabinetNo"
                  clearable
                  placeholder="请输入"
                  style="width: 180px"
                />
              </el-form-item>
              <el-form-item
                class="my-form-item"
                label="是否混箱"
                prop="isMixed"
              >
                <el-select
                  v-model="queryForm.isMixed"
                  clearable
                  placeholder="请选择类型"
                  style="width: 180px"
                >
                  <el-option
                    v-for="item in yseOrNo"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                class="my-form-item"
                label="报关方式"
                prop="customsType"
              >
                <el-select
                  v-model="queryForm.customsType"
                  clearable
                  placeholder="请选择类型"
                  style="width: 180px"
                >
                  <el-option
                    v-for="item in customsType"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                class="my-form-item"
                label="清关方式"
                prop="taxType"
              >
                <el-select
                  v-model="queryForm.taxType"
                  clearable
                  placeholder="请选择类型"
                  style="width: 180px"
                >
                  <el-option
                    v-for="item in taxType"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </div>
          </div>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button native-type="submit" @click="reset('queryForm')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel>
        <el-button
          :loading="loadingExPort"
          size="mini"
          type="primary"
          @click="exPort"
        >
          底单导出
        </el-button>
        <el-button
          :loading="loadingExPortPri"
          size="mini"
          type="primary"
          @click="exPortPri"
        >
          报关资料导出
        </el-button>

        <!-- 新增的下拉按钮 -->
        <el-dropdown trigger="click" @command="handleExportEuCommand">
          <el-button size="mini" type="primary">
            欧线清关资料导出
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="YUHUI">裕辉清关资料</el-dropdown-item>
            <el-dropdown-item command="CA">CA清关资料</el-dropdown-item>
            <el-dropdown-item command="ZHUOYUE">卓越清关资料</el-dropdown-item>
            <el-dropdown-item command="ATHINA">Athina清关资料</el-dropdown-item>
            <el-dropdown-item command="JACKSTAR">
              Jackstar清关资料
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-dropdown trigger="click" @command="handleExportEnCommand">
          <el-button size="mini" type="primary">
            英线清关资料导出
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="KQ">KQ清关资料</el-dropdown-item>
            <el-dropdown-item command="FOURPLINK">4Plink</el-dropdown-item>
            <el-dropdown-item command="DFS">DFS</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-button size="mini" type="primary" @click="openColumnSettings">
          设置列
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>
    <div style="height: calc(100vh - 420px)">
      <vxe-table
        id="draftCopyTable"
        ref="tableRef"
        v-loading="listLoading"
        border
        :checkbox-config="{ trigger: 'row', range: true }"
        :custom-config="customConfig"
        :data="tableData"
        height="100%"
        :row-config="{ isHover: true, isCurrent: true, height: 35 }"
        :scroll-y="{ enabled: true }"
        show-header-overflow
        show-overflow
        @checkbox-all="selectAllEvent"
        @checkbox-change="selectAllEvent"
      >
        <vxe-column
          align="center"
          field="selectOption"
          fixed="left"
          type="checkbox"
          width="55"
        />
        <vxe-column
          align="center"
          field="indexOption"
          fixed="left"
          title="序号"
          type="seq"
          width="70"
        >
          <template #default="{ seq }">
            {{ seq + (queryForm.pageNum - 1) * queryForm.pageSize }}
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          field="cabinetNo"
          min-width="140"
          title="柜号"
        />
        <vxe-column
          align="center"
          field="jobNo"
          min-width="140"
          title="运单号"
        />
        <vxe-column
          align="center"
          field="cusProductCnName"
          min-width="140"
          title="客户中文品名"
        />
        <vxe-column
          align="center"
          field="cusProductEnName"
          min-width="140"
          title="客户英文品名"
        />
        <vxe-column
          align="center"
          field="hsCode"
          min-width="120"
          title="海关编码"
        />
        <vxe-column
          align="center"
          field="reportCnName"
          min-width="140"
          title="申报中文品名"
        />
        <vxe-column
          align="center"
          field="reportEnName"
          min-width="140"
          title="申报英文品名"
        />
        <vxe-column
          align="center"
          field="hsCodeCn"
          min-width="120"
          title="中国海关编码"
        />
        <vxe-column
          align="center"
          field="reportFactor"
          min-width="120"
          title="中国申报要素"
        />
        <vxe-column
          align="center"
          field="materialCn"
          min-width="140"
          title="中文材质"
        />
        <vxe-column
          align="center"
          field="materialEn"
          min-width="140"
          title="英文材质"
        />
        <vxe-column
          align="center"
          field="childJobNo"
          min-width="240"
          show-overflow-tooltip
          title="子单号"
        />
        <vxe-column align="center" field="brand" min-width="140" title="品牌" />
        <vxe-column
          align="center"
          field="brandType"
          min-width="140"
          title="品牌类型"
        />
        <vxe-column align="center" field="specs" min-width="140" title="规格" />
        <vxe-column align="center" field="uses" min-width="140" title="用途" />
        <vxe-column
          align="center"
          field="goodsType"
          min-width="120"
          title="货物类型"
        />
        <vxe-column align="center" field="unit" min-width="80" title="单位" />
        <vxe-column
          align="center"
          field="pcs"
          min-width="80"
          show-overflow-tooltip
          title="件数"
        />
        <vxe-column
          align="center"
          field="netWeight"
          min-width="100"
          title="净重"
        />
        <vxe-column
          align="center"
          field="receiveNetWeight"
          min-width="100"
          title="收货总净重"
        />
        <vxe-column align="center" field="qty" min-width="80" title="数量" />
        <vxe-column
          align="center"
          field="vol"
          min-width="80"
          show-overflow-tooltip
          title="体积"
        />
        <vxe-column
          align="center"
          field="splitVol"
          min-width="100"
          show-overflow-tooltip
          title="拆分体积"
        />
        <vxe-column
          align="center"
          field="grossWeight"
          min-width="80"
          title="毛重"
        />
        <vxe-column
          align="center"
          field="splitGrossWeight"
          min-width="100"
          title="拆分毛重"
        />
        <vxe-column
          align="center"
          field="receiveGrossWeight"
          min-width="100"
          title="收货总实重"
        />
        <vxe-column
          align="center"
          field="spliTreceiveGrossWeight"
          min-width="120"
          title="拆分收货总实重"
        />
        <vxe-column align="center" field="price" min-width="80" title="单价" />
        <vxe-column
          align="center"
          field="totalPrice"
          min-width="80"
          title="总价"
        />
        <vxe-column
          align="center"
          field="length"
          min-width="80"
          show-overflow-tooltip
          title="长"
        />
        <vxe-column
          align="center"
          field="width"
          min-width="80"
          show-overflow-tooltip
          title="宽"
        />
        <vxe-column
          align="center"
          field="height"
          min-width="80"
          show-overflow-tooltip
          title="高"
        />
        <vxe-column
          align="center"
          field="transactionUrl"
          fixed="right"
          min-width="80"
          show-overflow-tooltip
          title="链接"
        >
          <template #default="{ row }">
            <a :href="row.transactionUrl" target="_blank">
              {{ row.transactionUrl }}
            </a>
          </template>
        </vxe-column>

        <vxe-column
          align="center"
          field="customsType"
          min-width="80"
          title="报关方式"
        />
        <vxe-column
          align="center"
          field="taxType"
          fixed="right"
          min-width="80"
          title="清关方式"
        />
        <vxe-column
          align="center"
          field="createBy"
          min-width="100"
          title="创建人"
        />
        <vxe-column
          align="center"
          field="createTime"
          min-width="160"
          title="创建时间"
        />
        <vxe-column
          align="center"
          field="isMixed"
          fixed="right"
          min-width="80"
          title="是否混装"
        >
          <template #default="{ row }">
            <el-tag :type="row.isMixed ? 'success' : 'danger'">
              {{ row.isMixed ? '是' : '否' }}
            </el-tag>
          </template>
        </vxe-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </vxe-table>
    </div>
    <div class="cont">
      全部合计：
      <span>订单毛重：{{ fomr.totalGrossWeight | toFixedNum }}</span>
      <span>订单净重：{{ fomr.totalNetWeight | toFixedNum }}</span>
      <span>收货毛重：{{ fomr.totalReceiveGrossWeight | toFixedNum }}</span>
      <span>收货净重：{{ fomr.totalReceiveNetWeight | toFixedNum }}</span>
      <span>件数：{{ fomr.totalPcs }}</span>
      <span>总数量：{{ fomr.totalQty }}</span>
      <span>金额：{{ fomr.totalPrice | toFixedNum }}</span>
      <span>体积：{{ fomr.totalVol | toFixedNum }}</span>
    </div>
    <div class="cont">
      选中数据合计：
      <span>订单毛重：{{ selectForm.grossWeight }}</span>
      <span>订单净重：{{ selectForm.netWeight }}</span>
      <span>收货毛重：{{ selectForm.receiveGrossWeight }}</span>
      <span>收货净重：{{ selectForm.receiveNetWeight }}</span>
      <span>件数：{{ selectForm.pcs }}</span>
      <span>总数量：{{ selectForm.qty }}</span>
      <span>金额：{{ selectForm.totalPrice }}</span>
      <span>体积：{{ selectForm.vol }}</span>
    </div>
    <el-pagination
      background
      :current-page="queryForm.pageNum"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :page-sizes="[100, 200, 300, 500, 1000]"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <Preview ref="preview" />
    <!-- <el-dialog
      title="上传Excel"
      :visible.sync="dialogFormVisible"
      width="500px"
      @close="close"
    >
      <el-upload
        class="upload-demo"
        drag
        action="#"
        ref="upload"
        v-loading="loadingDemo"
        :before-upload="beforeAvatarUpload"
        :on-change="handleChangeOne"
        :on-remove="handleRemoveOne"
        :http-request="handleAvatarSuccessImg"
        :file-list="fileListOne"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>
      </el-upload>
    </el-dialog> -->
    <Sync ref="sync" />
  </div>
</template>

<script>
  // 控制单元格颜色所需的变量
  let color_type = ''
  let color_lastIdList = []

  import {
    exportClearanceData,
    exportDraftCopy,
    exportProfile,
    pageDraftCopyThree,
  } from '@/api/cmsApi'

  import tableConfigMixin from '@/mixins/tableConfigMixin'
  import Preview from '@/vab/components/Preview'
  import Sync from './components/sync'
  export default {
    name: 'ClearanceList',
    components: {
      Preview,
      Sync,
    },
    filters: {
      toFixedNum: function (value) {
        return value.toFixed(2)
      },
    },
    mixins: [tableConfigMixin],
    data() {
      return {
        customsType: ['自备', '买单'],
        taxType: ['自税', '包税'],
        tableData: [],
        fold: true,
        loadingExPort: false,
        loadingExPortPri: false,
        listLoading: false,
        dialogFormVisible: false,
        loadingDemo: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        importType: '',
        fileListOne: [],
        yseOrNo: ['是', '否'],
        file: [],
        height: this.$baseTableHeight(3) + 50,
        queryForm: {
          cabinetNo: '',
          isMixed: '',
          customsType: '',
          taxType: '',
          jobNo: '',
          pageNum: 1,
          pageSize: 500,
          idList: [],
          exportType: '',
          jobNoList: [],
        },
        //全部数据合计
        fomr: {
          totalNetWeight: 0,
          totalGrossWeight: 0,
          totalReceiveNetWeight: 0,
          totalReceiveGrossWeight: 0,
          totalPcs: 0,
          totalQty: 0,
          totalPrice: 0,
          totalVol: 0,
        },
        //选中数据合计
        selectForm: {
          receiveNetWeight: 0,
          receiveGrossWeight: 0,
          grossWeight: 0,
          netWeight: 0,
          pcs: 0,
          qty: 0,
          totalPrice: 0,
          vol: 0,
        },
        pickerOptions: {
          disabledDate(v) {
            return v.getTime() < new Date().getTime() - 86400000
          },
        },
        colorRow: 1,
      }
    },
    mounted() {},
    created() {
      this.queryList()
    },
    methods: {
      selectAllEvent({ records }) {
        console.log(2323)
        console.log(records)
        this.queryForm.idList = []
        this.queryForm.idList = records.map((item, index, array) => item.id)
        this.queryForm.jobNoList = records.map(
          (item, index, array) => item.jobNo
        )
        this.selectForm.grossWeight = this.countTotal(records, 'grossWeight')
        this.selectForm.netWeight = this.countTotal(records, 'netWeight')
        this.selectForm.pcs = this.countTotalTwo(records, 'pcs')
        this.selectForm.qty = this.countTotalTwo(records, 'qty')
        this.selectForm.totalPrice = this.countTotal(records, 'totalPrice')
        this.selectForm.vol = this.countTotal(records, 'vol')
        this.selectForm.receiveNetWeight = this.countTotal(
          records,
          'receiveNetWeight'
        )
        this.selectForm.receiveGrossWeight = this.countTotal(
          records,
          'receiveGrossWeight'
        )
      },
      //选中计算
      countTotal(arr, keyName) {
        let $total = 0
        $total = arr.reduce(function (total, currentValue, currentIndex, arr) {
          return currentValue[keyName] ? total + currentValue[keyName] : total
        }, 0)
        return $total.toFixed(2)
      },
      countTotalTwo(arr, keyName) {
        let $total = 0
        $total = arr.reduce(function (total, currentValue, currentIndex, arr) {
          return currentValue[keyName] ? total + currentValue[keyName] : total
        }, 0)
        return $total
      },
      // 处理导出逻辑
      async handleExport(exportFunction, loadingState) {
        try {
          this[loadingState] = true
          const res = await exportFunction(this.queryForm)
          this[loadingState] = false
          this.jump(res.data)
        } catch (error) {
          this[loadingState] = false
          this.$message.error('导出失败!')
        }
      },
      exPort() {
        this.handleExport(exportDraftCopy, 'loadingExPort')
      },
      exPortPri() {
        this.handleExport(exportProfile, 'loadingExPortPri')
      },
      //重置
      reset(queryForm) {
        this.$refs[queryForm].resetFields()
        this.queryForm.pageNum = 1
        this.queryList()
      },
      handleSizeChange(val) {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = val
        this.queryList()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNum = val
        this.queryList()
      },
      // 设置单元格样式
      cellClassName({ row, column }) {
        // 先判断是否为高风险行
        if (row.isHighRisk === 1) {
          return 'high-risk-row'
        }

        // 原有的分组样式逻辑
        if (!row.hasGroup) {
          return null
        }

        if (column.fixed) {
          return null
        }

        let id = row.id
        if (!color_type) {
          color_type = 1
          color_lastIdList = row.idList
        }

        if (color_lastIdList.indexOf(id) > -1) {
          if (color_type === 1) {
            return 'cell-bg-1'
          } else {
            return 'cell-bg-2'
          }
        } else {
          color_lastIdList = row.idList
          if (color_type === 1) {
            color_type = 2
          } else {
            color_type = 1
          }
        }

        if (color_lastIdList.indexOf(id) > -1) {
          if (color_type === 1) {
            return 'cell-bg-1'
          } else {
            return 'cell-bg-2'
          }
        }
      },
      handleRowClick({ row, column }) {
        console.log(row)
        console.log(column)
        if (row && row.title == '操作') {
          return
        }
        this.$refs.tableSort.toggleCheckboxRow(row)
      },
      handleQuery() {
        this.queryForm.pageNum = 1
        this.queryList()
      },
      //查询
      async queryList() {
        this.listLoading = true
        const res = await pageDraftCopyThree(this.queryForm)
        // 全部合计
        this.fomr.totalNetWeight = res.data.totalNetWeight
        this.fomr.totalGrossWeight = res.data.totalGrossWeight
        this.fomr.totalReceiveNetWeight = res.data.totalReceiveNetWeight
        this.fomr.totalReceiveGrossWeight = res.data.totalReceiveGrossWeight
        this.fomr.totalPcs = res.data.totalPcs
        this.fomr.totalQty = res.data.totalQty
        this.fomr.totalPrice = res.data.totalPrice
        this.fomr.totalVol = res.data.totalVol

        // 选中数据合计
        this.selectForm.receiveNetWeight = 0
        this.selectForm.receiveGrossWeight = 0
        this.selectForm.grossWeight = 0
        this.selectForm.netWeight = 0
        this.selectForm.pcs = 0
        this.selectForm.qty = 0
        this.selectForm.totalPrice = 0
        this.selectForm.vol = 0

        this.total = res.data.totalSize
        this.tableData = res.data.pageList
        this.listLoading = false
      },

      handleFold() {
        this.fold = !this.fold
      },
      jump(data) {
        this.$router.push({
          name: 'taskManage',
          params: {
            taskNo: data,
          },
        })
      },
      handleChangeOne(file, fileList) {
        if (fileList.length > 0) {
          this.fileListOne = [fileList[fileList.length - 1]]
        }
      },
      handleRemoveOne(file, fileList) {
        this.queryForm.appFile = null
        for (let i = 0; i < this.fileListOne.length; i++) {
          if (this.fileListOne[i].uid == file.uid) {
            this.fileListOne.splice(i, 1)
            break
          }
        }
      },
      handleAvatarSuccessImg(params) {
        this.file.push(params.file)
        this.bt()
      },
      async bt() {
        this.loadingDemo = true
        for (let i = 0; i < this.file.length; i++) {
          this.queryForm.appFile = this.file[i]
        }
        let form = new FormData()
        form.append('file', this.queryForm.appFile)
        try {
          if (this.importType == 1) {
            const res = await importBasicFileForInsert(form)
          } else {
            const res = await importBasicFileForUpdate(form)
          }
          this.clone()
        } catch (error) {
          this.clone()
        }
      },
      clone() {
        this.loadingDemo = false
        this.dialogFormVisible = false
        this.close()
        this.file = []
        this.$refs.upload.clearFiles()
        this.queryList()
      },
      //弹出框隐藏回调
      close() {
        this.$refs.upload.clearFiles()
        this.importType = ''
      },
      // 新增处理欧线清关资料导出的方法
      handleExportEuCommand(command) {
        if (!this.queryForm.idList.length) {
          this.$message.warning('请勾选导出数据')
          return
        }
        this.commandFunction(exportClearanceData, command)
      },

      // 新增处理英线清关资料导出的方法
      handleExportEnCommand(command) {
        if (!this.queryForm.idList.length) {
          this.$message.warning('请勾选导出数据')
          return
        }
        this.commandFunction(exportClearanceData, command)
      },
      // 处理导出逻辑
      async commandFunction(exportFunction, command) {
        try {
          const data = {
            jobNos: this.queryForm.jobNoList,
            clearanceTemplateType: command,
          }
          const res = await exportFunction(data)
          this.jump(res.data)
        } catch (error) {
          console.log(error)
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .comprehensive-table-container {
    position: relative;
  }

  ::v-deep .left-panel {
    margin: 0px !important;
  }

  .posit {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }

  .cont {
    margin-top: 20px;

    span {
      margin-right: 20px;
    }
  }

  ::v-deep {
    .el-table {
      .el-table__body-wrapper::-webkit-scrollbar {
        width: 12px;
        height: 12px;
      }

      .el-table__body-wrapper::-webkit-scrollbar-thnmb {
        background-color: #ccc !important;
        border-radius: 5px;
      }

      .el-table-fixed .el-table__body {
        padding-bottom: 12px;
      }
    }

    .vab-query-form[data-v-64063760] .top-panel {
      margin: 0px;
    }

    .el-form-item {
      margin-bottom: 0px;
    }

    .left-panel {
      margin: 0px !important;
    }

    .left-panel {
      margin: 0px !important;
    }

    .el-table__body-wrapper.is-scrolling-right ~ .el-table__fixed-right {
      height: auto !important;
      bottom: 10px !important;

      &::before {
        background-color: transparent;
      }
    }

    .el-table__body-wrapper.is-scrolling-left ~ .el-table__fixed {
      height: auto !important;
      bottom: 10px !important;

      &::before {
        background-color: transparent;
      }
    }

    .el-dialog__body {
      text-align: center;
    }

    .rowStyle {
      background-color: #e8f7fd !important;
      color: #1890ff;
    }

    // 添加高风险行的样式
    .high-risk-row {
      background-color: #f56c6c !important;
      color: #ffffff !important;

      // 确保下拉菜单等内容也使用白色
      .el-dropdown-link {
        color: #ffffff !important;
      }

      // 确保链接也使用白色
      a {
        color: #ffffff !important;
      }
    }
  }

  .cont-form {
    display: flex;
  }

  .columns-list {
    display: flex;
    flex-wrap: wrap;
  }

  .columns-list .el-checkbox {
    flex: 0 0 calc(20% - 10px); /* 每个复选框占1/5宽，减去间距 */
    margin: 5px;
    text-align: left; /* 首字符对齐 */
  }

  .columns-list .first-in-row {
    margin-left: 0; /* 第一个元素没有左边距 */
  }

  /* 确保全选复选框和按钮在同一行 */
  .dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dialog-footer .el-checkbox {
    margin-right: auto; /* 将全选复选框推到左侧 */
  }

  .el-dropdown {
    margin-right: 10px;
    margin-bottom: 10px;
  }
</style>
